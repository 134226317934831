@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  /* Change this path if needed */
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
  font-display: swap;
}

.section_cards {
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(3, 1fr);
  /* margin-bottom: 55px; */
  justify-content: center;
}


@media (max-width: 580px) {
  .section_cards {
    grid-template-columns: repeat(1, 1fr);
  }
}


@media (min-width: 581px) and (max-width: 1080px) {
  .section_cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

.rowhome {
  --bs-gutter-x: 0rem !important;
}

.morebtn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: 0.5rem;
}

.adddata {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.banner-content-wrapper {
  position: relative;
  z-index: 99;
}

.logo-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}

.expandable-text p {
  margin: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: rgb(17, 17, 17);
}

.btnmore {
  background-color: #fff;
  border: 3px solid #000;
  border-radius: 10px;
  color: #000;
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  padding: 15px 42px;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.content {
  align-items: center;
  border-radius: 12px;
  bottom: 0;
  padding: 10px;
  position: absolute;
  padding-left: 15px;
  padding-right: 12px;
  right: 0;
  width: calc(100% - 2px);
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5px);
}


.title {
  color: rgba(255, 255, 255, 1.0);
  /* font-size: 4vh; */
  font-family: 'Poppins', sans-serif;
  /* font-weight: 400; */
}

.buttons-wrapper {
  display: flex;
  gap: 29px;
  justify-content: flex-start;
}

.social-icon {
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
  vertical-align: middle;
  max-width: 46px;
}

.socialimg {
  display: block;
  max-width: 46px;
  border-style: none;
  overflow-clip-margin: content-box;
  overflow: clip;
}


.header5 {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 35px;
}

.row-fluid {
  margin: 0 auto;
  max-width: 1600px;
  width: 90%;
}


.subtitle {
  color: rgba(255, 255, 255, 1.0);
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.arrow-icon {
  align-items: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 12px;
  display: flex;
  height: 57px;
  max-width: 80px;
  justify-content: center;
  border-color: white;
  margin-left: 10px;
  border-width: 1px;
  border-style: solid;
}

.row-card {
  --bs-gutter-x: 0rem !important;
}


.addetails {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: white;
  margin: 4px;
}


.heading {
  margin-bottom: 30px;
  text-align: center;
}

.divider {
  background: #000;
  height: 2px;
  margin: 4px auto auto;
  opacity: .25;
  width: 150px;
}

.imagecard {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9px;
  min-height: 349px !important;
  position: relative;
  width: 100%;
  background-color: gray;
}


@media (max-width: 767px) {
  .imagecard {
    min-height: 200px !important;
  }
}

.cardcontainer {
  margin: 30px auto;
  max-width: 1600px;
  width: 90%;
}


.overlayStyle {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.normaltextStyle {
  color: white;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.mediumtextstyle {
  color: white;
  font-size: 1.5em;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-display: swap;
}

.login_header_text {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.error_string {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}

.boldTextStyle {
  color: white;
  font-size: 2.2em;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.normaltextLineStyle {
  width: 15vmin;
  background-color: rgba(255, 255, 255, 0.5);
  height: 0.5px;

  margin-right: 5px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.error-message {
  color: red;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 12px;
  margin-left: 10px;
}

.heilightdiv {
  border: 1px solid rgb(221, 216, 216);
  border-radius: 5px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 15px;

}

.label_flot {
  position: absolute;
  top: -8px !important;
  left: 10px !important;
  /* Adjust the left position based on your preference */
  background-color: white;
  /* Optional: Set background color to match the container */
  padding: 0 5px;
  /* Optional: Add padding for better aesthetics */
  color: #555;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  ;
  /* Optional: Set label text color */
}

.hilight_floatlabel {
  position: relative;
  top: -10px !important;
  /* Adjust the left position based on your preference */
  background-color: white;
  /* Optional: Set background color to match the container */
  padding: 0 5px;
  /* Optional: Add padding for better aesthetics */
  color: #555;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  ;
}


.inputheilight {
  border: none;
  /* Remove all borders */
  border-radius: 0;
  /* border-bottom: 1px solid rgb(238, 235, 235);  */
  outline: none;
  height: 45px !important;
  width: 100%;

}

.inputheilight:focus {
  border: none !important;
  /* Remove all borders when focused */
  border-bottom: 1px solid rgb(249, 245, 245);
  /* Ensure bottom border remains */
  outline: none;
  min-height: 45px !important;
}

.next-btn {
  border: 1px solid orange !important;
  background-color: white !important;
  color: orange !important;
  min-height: 45px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.publishbtn {
  background-color: rgb(241, 144, 7);
  border: none;
  color: white;
  padding: 5px 16px;
  border-radius: 5px;
  cursor: pointer;

  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  min-height: 45px;
}

.logininput {
  margin-bottom: 15px;
  outline: none;
  min-height: 45px;
  border-style: solid;
  border-color: white;
  width: 100%;
  padding: 0px 10px 0px 10px;
  border-radius: 2px;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.inputborder {
  background-color: #ddd5d5;
  bottom: 0px;
  content: "";
  height: 0.5px;
  left: 0;
  position: relative;
  width: 100%
}

.input {
  min-height: 45px !important;
}

.headersignup {
  font-family: 'Poppins', sans-serif;
  font-size: 4vh;
  color: white;
  align-items: 'center';
  justify-content: 'center';

}

.option.selected {
  border-color: orange;
  color: orange;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
}


.option {
  border: 1px solid rgb(221, 216, 216);
  border-radius: 20px;
  padding: 2px 10px;
  margin-right: 10px;
  cursor: pointer;
  color: rgb(221, 216, 216);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.redtext {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: white;
}

.content-inner {
  background-color: rgba(255, 177, 0, .1);
  border-radius: 9px;
  padding: 10px 20px;
}

.custom-list {
  list-style: none;
  /* Remove default list styles */
  padding: 0;
  /* Remove default padding */
  margin: 0;
  font-size: 2.5vmin;
}

.right_image_section {
  padding-bottom: 15px
}

.right_column {
  height: calc(100%);
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px;
}



.pic {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding-bottom: 50%;
  height: 100%;
}

.li {
  font-weight: 500;
  line-height: 1.2;
  list-style-type: none;
  padding-left: 4vh !important;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 15px !important;
}

.li::before {
  content: '';
  /* Empty content */
  display: inline-block;
  background-size: cover;
  /* Adjust as needed */
}

.contacts-list {
  margin-top: 10px;
  flex: 1;
  overflow-y: auto;
}

.contact_div {
  transition: background-color 0.3s ease;
  padding-left: 10px;
}

.contact_div.selected {
  background: #fbe19ae7 !important;
  padding-left: 10px;
}

.contact-item {
  padding: 10px;
  cursor: pointer;

  flex-direction: column;
  display: flex;
  flex: 1;
}

.contact-item.selected {
  background: #fbe19ae7 !important;
}

.image-row .image-title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 120px;
  /* Extend to the right to cover full width */
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  align-items: center;
  /* Center items vertically */
  justify-content: flex-start;
  /* Align items to the left */
  padding: 0.8rem;
  transition: opacity 0.3s ease;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 20px;
  /* Rotate text to appear bottom-to-top */
}

.image-row.expanded .image-title {
  display: none;

}

.rowimage {
  --bs-gutter-x: 0.9rem !important;
}

.pic_items {
  /* margin-bottom: 8px; */
  margin-bottom: 15px;
  display: grid;
}

.dashed-view {
  border: 1px dashed rgb(211, 206, 206);
  border-radius: 5px;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 12vmin !important;
  height: 12vmin !important; */
  width: 100px;
  height: 100px;
  background-color: rgba(243, 243, 243, 0.714);
  margin-bottom: 10px;
}


.upload-label {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(243, 243, 243, 0.714);
}


.upload-label input[type="file"] {
  /* Position the input field */
  width: 100%;
  /* Make it cover the entire area of its parent */
  height: 100%;
  /* Make it cover the entire area of its parent */
  opacity: 0;
  /* Hide the input field */
  cursor: pointer;
  /* Show pointer cursor */

}

.popover-content {
  /* Add your CSS styles here */
  padding: 0px;
  background-color: rgb(92, 111, 133);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popover-content button {
  /* Add styles for buttons inside the popover content */
  background-color: rgb(92, 111, 133);
  border-color: rgb(92, 111, 133);
}

.hero-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}

.upload-placeholder {
  /* height: 2vh;
    width: 2vh; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

.add_btn2 {
  background-color: white;
  border-radius: 5px;
  border: 1px dashed #000;
  color: rgb(75, 70, 70);
  /* padding: 8px 16px; */
  width: 90%;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
}


.next-btn1 {
  border: 1px solid orange;
  background-color: white;
  color: orange;
  padding: 8px 6vh;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.ant-input-group>.ant-input:last-child {
  height: 45px;
  border-radius: 5px;
}

.antd-country-phone-input .ant-select {
  height: 45px;
  border-radius: 5px;
}


.antd-country-phone-input .ant-input-group-addon .ant-select-selection-item {
  align-items: center !important;
}

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 100%;
  align-items: center;
}

.headernavbar {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(9px);

}