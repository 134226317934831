.maindiv {
    padding-left: 5vmin;
    padding-right: 5vmin;
    padding-bottom: 10vmin;
    padding-top: 2vmin;
    margin-top: 10vh;
}

.btn_7days {
    border: none;
    background-color: white;
    color: rgb(157, 148, 148);
    padding: 8px 16px;
    cursor: pointer;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.incompletetext {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: red;
}

.add_btn {
    background-color: white;
    border-radius: 5px;
    border-width: 1px;
    color: rgb(75, 70, 70);
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.headertext_1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 2.5vh;
    color: black;
}

.headertext_2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 2.5vh;
    color: black;
}


.adds_section_cards {
    margin-bottom: 10px;
}

.card_content {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.col_add {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-left: 10px !important;
    margin-top: 10px !important;
}

.row_add {
    margin-left: 0px !important;
}


.add_name {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.create_addbtn {
    background-color: white;
    border-radius: 5px;
    border: 1px dashed #000;
    color: rgb(75, 70, 70);
    /* padding: 8px 16px; */
    width: 40%;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    align-self: center;
}

.add_date {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 12px;
    color: dimgray;
    text-align: center;
}

.publish_btn {
    background-color: white;
    color: gray;
    border: 1.5px solid orange;
    padding: 5px 5px;
    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 20px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    margin-top: 10px;
}

.publish_btn:hover,
.publish_btn:focus {
    background-color: orange;
    color: white;
}


.ad_unpublish_btn {
    background-color: white;
    color: gray;
    border: 1.5px solid orange;
    border-radius: 5px;
    padding: 5px 5px;
    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 20px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    margin-top: 10px;
}

.ad_unpublish_btn:hover,
.ad_unpublish_btn:focus {
    background-color: orange;
    color: white;
}



.shadowmessage {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 0px 10px 0px 10px;
    border-radius: 5px;
}

.analytics_border {
    height: 100%;
    width: 1px;
    background-color: rgb(220, 222, 230);
    margin-left: 10px;
}

.time {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: rgb(174, 181, 200);
}

.messagetext {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: rgb(174, 181, 200);
}

.messageadtext {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: rgb(64, 80, 123);
}

.messagename {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: rgb(32, 54, 101);
}

.messagetext p {
    margin-bottom: 0px !important;
}

.btn_read {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
}