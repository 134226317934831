.tabs {
  display: block;
  /* margin: 10px; */
}

.inactive-btn {
  background-color: rgba(255, 255, 255, 0);
  margin: 2px;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-top: 3px solid rgb(230, 230, 230);
}

.active-btn {
  background-color: rgba(255, 255, 255, 0);
  margin: 2px;
  color: orange;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-top: 3px solid #f5bb3d91;
}

.tab-content {
  margin-top: 20px;
}

.maincontainer {
  margin-top: 16vh;
  margin-left: 8vw;
  margin-right: 8vw;
}

.add_headertext3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 4vmin;
  color: black;
}

.headertext2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 2.5vmin;
  color: black;
}

.option-container {
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
}

.currencyoption {
  border: 1px solid rgb(221, 216, 216);
  border-radius: 20px;
  padding: 2px 10px;
  margin-right: 10px;
  cursor: pointer;
  color: rgb(221, 216, 216);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.currencyoption.selected {
  border-color: orange;
  color: orange;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.curenyheader {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.add_preview_btn {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgb(167, 162, 155);
  color: gray;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  min-height: 45px;
  min-width: 120px;
}

.add_back_btn {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgb(167, 162, 155);
  color: gray;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  min-height: 45px;
  min-width: 120px;
}

.quill_vehicle {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}

.quill_vehicle .ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: 35vmin !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #d3d2cfe7 !important;
}

.quill_gallery .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-color: #d3d2cfe7 !important;
}

.quill_adds {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}

.quill_adds .ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: 20vmin !important;
  /* height: 8vmin; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* border-radius: 5px; */
  border-color: #d3d2cfe7 !important;
}

.card_gallery {
  border: none !important;
  min-height: auto !important;
}

.card-header {
  background-color: rgba(255, 255, 255, 0) !important;
  border-bottom: none !important;
}

.section_btn {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

}

.add-dashed-view {
  border: 1px dashed rgb(211, 206, 206);
  border-radius: 5px;
  margin: 5px 5px 10px 0px;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12vh;
  height: 12vh;
  background-color: rgba(243, 243, 243, 0.714);
}

.placeholder_img {

  height: 2vmin;
  width: 2vmin;

}

.add-upload_image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.card-body-add {
  padding: 0px 0px 10px 0px;
}

.rownumber {
  padding-left: calc(var(--bs-gutter-x)* 0) !important;
  padding-right: calc(var(--bs-gutter-x)* 0) !important
}

.numberinput {
  min-height: 45px !important;
  border-style: solid;
  border-width: thin;
  border-radius: 5px;
  border-color: lightgray;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.numberinput:focus {
  outline: none;
}

.card-header {
  padding: 0px 10px 10px 0px !important;
}

.add_equipment_btn {
  border: none;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 231, 225) !important;
  background-color: white;
  color: rgb(32, 31, 30);
  padding: 8px 6vh;
  border-radius: 0px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  min-height: 30px !important;
}

.delete-btn {
  background-color: transparent;
  border: none;
  color: gray;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
}

input:disabled {
  cursor: default;
  background-color: white;
  color: black;
}


.checkbox-container {
  border: 1px solid #e6e4e4;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
}

.form-check {
  display: flex !important;
  align-items: center;
}


.custom-form-check-label {
  position: relative;
  top: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 12px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgb(47, 45, 45);
  font-size: 10px;
  margin-left: 10px;
  /* Adjust as needed */
}

.form-check .form-check-input {
  min-height: 3vmin;
  width: 3vmin;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 12px;
  /* background-color:rgba(141, 171, 192, 1) !important; */
}

.form-check-input {
  background-color: white !important;
  width: 20px !important;
  height: 20px !important;
}

.form-check-input:checked {
  background-color: orange !important;
  border: none;
}

.un_publish_btn {
  background-color: rgb(83, 81, 78);
  border: none;
  color: white;
  padding: 5px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  min-height: 45px;
  min-width: 120px;
}