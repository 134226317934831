.maindiv {
    padding-left: 5vmin;
    padding-right: 5vmin;
    padding-bottom: 10vmin;
    padding-top: 2vmin;
    margin-top: 10vh;
}

.btn_7days {
    border: none;
    background-color: white;
    color: rgb(157, 148, 148);
    padding: 8px 16px;
    cursor: pointer;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.add_btn {
    background-color: white;
    border-radius: 5px;
    border-width: 1px;
    color: rgb(75, 70, 70);
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.headertext_1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 2.5vh;
    color: black;
}

.headertext_main {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 3.5vh;
    color: black;
    margin-top: 20px;
    margin-bottom: 5vmin;
}

.headertext_2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 2.5vh;
    color: black;
}


.adds_section_cards {
    margin-bottom: 10px;
}

.card_content {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.col_add {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-left: 10px !important;
    margin-top: 10px !important;
}

.row_add {
    margin-left: 0px !important;
}



.row-buttons {
    margin-left: 0px !important;
    margin-right: 0px !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}


.add_name {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.add_date {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 12px;
    color: dimgray;
    text-align: center;
}

.publish_btn {
    background-color: white;
    color: gray;
    border: 1.5px solid orange;
    padding: 5px 5px;
    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 20px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    margin-top: 10px;
}

.publish_btn:hover,
.publish_btn:focus {
    background-color: orange;
    color: white;
}

.unpublish_btn {
    background-color: white;
    color: gray;
    border: 1.5px solid orange;
    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 20px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
}

.unpublish_btn:hover,
.unpublish_btn:focus {
    background-color: orange;
    color: white;
}

.analytics_number {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 30px;
    margin-left: 40px;
    margin-right: 20px;
}

.analytics_header {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: rgb(164, 169, 192);
    /* padding: 0px 20px 0px 20px; */
    letter-spacing: 1px;
    margin-left: 40px;
    margin-right: 20px;
}

.analytics_layout {
    margin: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 0px 0px 0px 10px */
}

.analytics_layout1 {
    margin: 10px 10px 10px 0px;
    display: flex;
    flex-direction: column;
    border: 0.5px solid gray;
    border-radius: 5px;
}

.analytics_border1 {
    height: 1px;
    width: 100%;
    background-color: rgb(220, 222, 230);
}

.incompletetextdash {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: red;
}

.analytics_header1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: rgb(164, 169, 192);
    /* padding: 0px 20px 0px 20px; */
    letter-spacing: 1px;
}

.shadowmessage {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 0px 10px 0px 10px;
    border-radius: 5px;
}

.analytics_border {
    height: 100%;
    width: 1px;
    background-color: rgb(220, 222, 230);
    margin-left: 10px;
}

.time {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: rgb(174, 181, 200);
}

.messagetext {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: rgb(174, 181, 200);
}

.messageadtext {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: rgb(64, 80, 123);
}

.messagename {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: rgb(32, 54, 101);
}

.messagetext p {
    margin-bottom: 0px !important;
}

.btn_read {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.sold_btn {
    background-color: rgb(240, 15, 15);
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.reserve_btn {
    background-color: rgb(49, 240, 15);
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 20px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.extend_btn {
    background-color: rgb(248, 196, 10);
    border: none;
    color: white;

    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 20px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.unreserve_btn {
    background-color: rgb(240, 172, 15);
    border: none;
    color: white;
    padding: 5px 5px;
    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 20px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.button-container {
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    flex-wrap: wrap;
    /* Allows items to wrap to the next line on smaller screens */
    padding: 0px 8px 8px 12px;
}

.responsive-btn {
    padding: 6px 6px;
    margin-left: 5px;
    margin-right: 5px;
    max-height: 40px;
    margin-top: 8px;
    margin-bottom: 0px;

}

.sold_ribbon {
    position: absolute;
    top: 80px;
    /* Adjusted to fit within the card */
    right: -25px;
    /* Adjusted to fit within the card */
    width: 150px;
    /* Adjusted width */
    background-color: rgb(255, 0, 0);
    color: white;
    text-align: center;
    line-height: 20px;
    /* Adjusted line height */
    transform: rotate(45deg);
    font-weight: bold;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    transform-origin: top right;
}