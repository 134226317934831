/* Container for the entire page */
.payment-success-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f6;
    /* light gray */
    padding: 1.5rem;

}

/* Card that holds all content */
.payment-success-card {
    background-color: #ffffff;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 2rem;
    max-width: 640px;
    width: 100%;
    margin-top: 60px;
}

/* Icon container */
.payment-success-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.payment-success-icon svg {
    color: #10b981;
    /* green */
    width: 3rem;
    height: 3rem;
}

/* Title and message */
.payment-success-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
}

.payment-success-message {
    text-align: center;
    color: #10b981;
    margin-top: 0.5rem;
}

/* Section styling (order summary, payment details, user info) */
.section {
    margin-top: 1.5rem;
}

.section-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 0.5rem;
}

.section-content {
    background-color: #f9fafb;
    padding: 1rem;
    border-radius: 0.5rem;
}

.section-content p {
    margin-bottom: 0.5rem;
}

/* Buttons container and styles */
.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.button-common {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    border: none;
}

/* Back button styles */
.button-back {
    background-color: #d1d5db;
    /* gray */
    color: #374151;
    transition: background-color 0.3s;
}

.button-back:hover {
    background-color: #9ca3af;
}

/* Receipt button styles */
.button-receipt {
    background-color: #2563eb;
    /* blue */
    color: #ffffff;
    transition: background-color 0.3s;
}

.button-receipt:hover {
    background-color: #1d4ed8;
}