.adminheadertext {
    font-size: 30px;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    color: black;
}

.instatext {
    font-size: 25px;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    color: black;
}

.instatextheader {
    font-size: 20px;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    color: gray;
}

.div_grid {
    border-radius: 5px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px;
    flex-direction: column;
    width: 30vmin;
    height: 30vmin;
}