.footer {
  padding: 4vh 0vmin 6vw 0vmin !important;
  position: relative;
  background-color: #000;
}

.addressText {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 35px;
}

.footer_container {
  margin: 0 auto;
  max-width: 1600px;
  width: 90%;
  padding: 0;
  background-color: red;
  display: flex;
  flex-direction: column;
}

.ul {
  list-style: none;
  padding: 0;

}

.para {
  margin-bottom: 0px !important;
}

.a {
  cursor: pointer;
  text-decoration: none;
  transition: all .15s linear;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 25px;
  line-height: 1.58;
  font-weight: 400;
}

.ul .a {
  color: #fff;
  /* Set this to the color you want */
  text-decoration: none;
}

.rowfinisher {
  --bs-gutter-x: 0rem !important;
}


@media (max-width: 768px) {
  .footer_left_column {
    margin-left: 20px;
    margin-right: 10px;
  }

  .footer_right_column {
    margin-left: 20px;
  }
}

.footer_buttons-wrapper {
  display: flex;
  gap: 29px;
  justify-content: center;
  margin-bottom: 5vmin;

}