.modal {
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;

}

.modal-inner {
    width: 50vw;
    display: flex;

}

.businessimage {
    width: 15vw;
    height: 15vmin;
    border-radius: 5px
}

.slider-button {
    background-color: white !important;
    border: 1px solid gray !important;
    height: 35px;
    width: 30px;
    margin-left: 5px;
    cursor: pointer;
    padding: 5px 5px !important;
    /* display: 'flex';
    justify-content: center !important;
    align-items: center !important; */
}

.slider-button span {
    color: gray;
    font-size: 15px;
}

.modal-header {
    border-bottom: none;
    width: 100%
}

.back-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.businees_image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.image-texture {
    /* Style your image and texture */
}

.submit-button {
    margin-top: 20px;
}

.next_button_text {
    border: 1px solid orange !important;
    background-color: white !important;
    color: orange !important;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
    max-width: 150px;
    align-self: center;
}

.modal-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.descriptionView {
    border: 1px solid rgb(221, 216, 216);
    min-height: 30vh;
    border-radius: 5px;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    min-width: 30vw;
}


.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    margin: auto;
}

.floatlabel {
    position: relative;
    top: -15px;
    left: 8px;
    /* Adjust the left position based on your preference */
    background-color: white;
    /* Optional: Set background color to match the container */
    padding: 0 5px;
    /* Optional: Add padding for better aesthetics */
    color: #555;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    ;
    /* Optional: Set label text color */
}

.descriptiontextarea {
    border: none;
    /* Remove all borders */
    border-radius: 5px;
    padding: 10px;
    min-height: 30vh;
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: -25px;
    border-top: 1px solid rgb(223, 215, 215);
}