.text {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.cancel-btn {
    background-color: rgb(167, 162, 155);
    border: none;
    color: white;
    width: 22vh;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.changepwinput {
    border: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 5px;
    align-items: center;
    padding-right: 10px;
    min-height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
}

.inputcpw {
    border: none !important;
    font-family: 'Poppins', sans-serif;
    font-size: 2vmin;
    font-weight: 400;
    width: 100%;
}

.inputcpw:focus {
    outline: none !important;
}