.dropdown-label-input {
  position: relative;
  margin-bottom: 15px;
  margin-top: 0px;
}

.dropdown_overview {
  position: relative;
  margin-bottom: 15px;
  margin-top: 0px;
}

.div_dropdown {
  border: 1px solid rgb(221, 216, 216);
  border-radius: 5px;
}

.input_dropdown {
  border: none !important;
  outline: none;
  margin-top: 0px !important;
  min-height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.label_dropdown {
  position: absolute;
  top: -8px;
  left: 8px;
  /* Adjust the left position based on your preference */
  background-color: white;
  /* Optional: Set background color to match the container */
  padding: 0 5px;
  /* Optional: Add padding for better aesthetics */
  color: #555;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  ;
  /* Optional: Set label text color */
}



/* .input {
  width: 100%;
  padding: 8px;
  min-height: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 20px;
  
} */

.inputtel {
  margin-top: 0px !important;
  min-height: 20px;
  margin-left: 5px;
  border-left: 1px solid rgb(221, 216, 216);
  border-right: none;
  border-bottom: none;
  border-top: none;
  border-radius: 0px;

}

.mobilecode {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}

.headertext_dropdown {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: black;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.inputtel:focus {

  border-left: 1px solid rgb(221, 216, 216);
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
}


.dropdown-icon {
  color: #555;
  /* Change color as needed */
  border-left: 1px solid rgb(221, 216, 216);
  padding-left: 15px;
  padding-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;

}

.dropdown-menu-details {
  /* position: absolute;
  top: calc(100% + 5px);
  right: 0;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 2000; */
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-height: 200px;
  /* Set a fixed height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  z-index: 5000;
  padding-bottom: 5px;
}

.dropdown-item {
  padding: 10px;
  /* border-bottom: 1px solid #292828 !important; */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdown-item:hover {
  background-color: #f5f5f5;

}