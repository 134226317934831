.col-reg {
    padding: 0 !important;
}

.reg-col-input {
    padding: 4px 4px 4px 4px !important;
}

.reg_subheadertext {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: black;
}

.note {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: rgb(139, 135, 135);
}

.successtext {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: black;

}