.companyLogo {
    background-color: white;
    border-radius: 8px;
    margin: 0 auto;
    max-width: 20vw;
    padding: 15px;
    text-align: center;
    vertical-align: middle;
    width: 100%;


}


.image {
    width: 100%;
    height: auto;
    aspect-ratio: auto;
    object-fit: contain;
}

.businesswrapperContainer {
    padding-top: 36px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.businessContainer {
    margin: 0 auto;
    max-width: 1600px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.heading_businesspage {
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
    font-size: 52px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    line-height: 1.37;
    text-align: left;
}

.popover-content_page {
    /* Add your CSS styles here */
    padding: 10px;
    background-color: rgb(92, 111, 133);
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.headingborder {
    background-color: #000;
    bottom: 5px;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    width: 175px;
}


.contact-info {
    margin-top: 0px !important;

}

.websitebtn {
    background-color: transparent;
    border: 3px solid #000;
    color: #000;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 5px;
    padding: 9px 20px;
    cursor: pointer;
    display: inline-block;
    font-size: .92rem;
    text-align: center;
    transition: all .15s linear;
    white-space: normal;
    text-decoration: none;
}




.header6 {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 30px;
}

.descriptionbody {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.58;
    word-break: break-word;
    color: #000;
}

.descriptionview {
    padding-top: 0px !important;
    padding-bottom: 42px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}



.description {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.58;
    word-break: break-word;
    color: #000;
    margin-top: 20px;
}

.galleryvw {
    padding-top: 0px !important;
    padding-bottom: 99px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.pic {
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding-bottom: 50%;

}

.right_image_section {
    padding-bottom: 15px;
}

.right_column {
    padding-left: 0px;
    padding-right: 0px;
}

.rowimage {
    --bs-gutter-x: 0.9rem !important;

}

.pic_businesspage {
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding-bottom: 50%;
    height: 100%;
}

.adtext {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    margin-top: 22px;
    margin-bottom: 20px;
    display: block !important
}