.measurement_overview {
  position: relative;
  margin-bottom: 15px;
  margin-top: 0px;

}

.input_measurement {
  border: none !important;
  outline: none;
  margin-top: 0px !important;
  min-height: 45px;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
}

.input_measurement:focus {
  border: none !important;

}

.div_measurement {
  border: 1px solid rgb(221, 216, 216);
  border-radius: 5px;
}

label {
  position: absolute;
  top: -8px;
  left: 8px;
  /* Adjust the left position based on your preference */
  background-color: white;
  /* Optional: Set background color to match the container */
  padding: 0 5px;
  /* Optional: Add padding for better aesthetics */
  color: #555;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  ;
  /* Optional: Set label text color */
}


.inputtel {
  margin-top: 0px !important;
  min-height: 20px;
  margin-left: 5px;
  border-left: 1px solid rgb(221, 216, 216);
  border-right: none;
  border-bottom: none;
  border-top: none;
  border-radius: 0px;

}

.mobilecode {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}

.mobiletext {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #898585;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.inputtel:focus {

  border-left: 1px solid rgb(221, 216, 216);
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
}

.PhoneInputCountryIconUnicode {
  display: none !important;
}

.measurementinput-dropdown-icon {
  border-left: 1px solid rgb(221, 216, 216);
  padding-left: 10px;
  padding-right: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  color: gray;
}