.userselection_shadowdiv {
    background-color: rgba(255, 255, 255, 0.4) !important;
    box-shadow: 0 5px 5px rgba(200, 192, 192, 0.2);
    border-radius: 8px !important;
    padding-right: 8vmin !important;
    padding-left: 8vmin !important;
    backdrop-filter: blur(15px);
    margin: 20px;
    padding: 20px;
    display: flex;

    align-items: center;
    flex-direction: column;
    min-height: 500px;
}

.btnnext {
    border: none !important;
    color: white;
    width: 100%;
    padding: 10px;
    padding-right: 5vmin !important;
    padding-left: 5vmin !important;
    border-radius: 10px;
    margin-bottom: 20px !important;
    background-color: rgba(255, 255, 255, 0.3) !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    /* backdrop-filter: blur(10px); */
}

.main-header {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: white;
}

.subtext {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}