.uploaded-image1 {
  border-radius: 5px;
  object-fit: contain;
  width: 100%;
  height: 100px;
}




.imagecard_card {
  height: 100px !important;
  position: relative;
}

/* .modal button {
  border: 1px solid orange !important;
  background-color: white;
  color: orange;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
} */

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}