.modal-image {
    display: none;
    position: fixed;
    z-index: 6000;
    /* padding-top: 30px; */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.modal-content-image {
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.close {
    position: absolute;
    display: flex;
    align-self: flex-end;
    margin-right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    z-index: inherit;

}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.5);
}

.next {
    right: 10px;
    border-radius: 3px 3px 3px 3px;
    background-color: orange !important;
}

.prev {
    left: 10px;
    border-radius: 3px 3px 3px 3px;
    background-color: orange !important;
}

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}