.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  /* width: 20%; */
  /* height:50%; */
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding-top: 8dvb;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  border-radius: 5px;
  width: 300px;
}

.modalstyle {
  background-color: rgba(0, 0, 0, 0);
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.modal h2 {
  margin-top: 0;
}



/* .modal button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #0056b3;
} */

/* This is optional, adjust the positioning as needed */
button {
  margin-top: 20px;
}

.login-btn {
  border: 1px solid orange;
  background-color: rgba(0, 0, 0, 0);
  color: orange;
  padding: 4px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.closebtn {
  border: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  padding: 4px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  background-color: rgba(0, 0, 0, 0);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
}