.normaltextrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.content_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10vmin;
  padding-right: 10vmin;
}

.view_line {
  background-color: white;
  height: 1px;
  width: 30px;
  margin-right: 5px;
}

.dashboard_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(17 17 17 / 30%);
  top: 0;
  left: 0;
}

.hubspotheader {
  color: white;
  font-weight: 400;
  font-size: 18px;
  font-family: Poppins, sans-serif;
}

.fade-in {
  transition: opacity 0.5s ease-in-out;
}

.formdiv.fade-in {
  opacity: 1;
}


.formdiv {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  border-radius: 5px !important;
  max-width: 484px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.1);
  flex-direction: column;
}


.banner-content-wrapper-home {
  position: relative;
  z-index: 99;
}



.loading-indicator {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
}

.step-row-1 {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: initial;
  margin: 0px !important;
  padding-bottom: 80px;

  display: flex;
}

.step-row-2 {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: initial;
  margin: 0px !important;
  padding-bottom: 52px;
  flex-direction: row-reverse;
  display: flex;
}

.col_images {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.image_after {
  border-radius: 14px;
  z-index: -1;
  right: -22px;
  top: -22px;
  background-color: rgba(0, 0, 0, 1);
  height: 353px;
  max-width: 100%;
  position: absolute;
  width: 445px;
}

.image_after1 {
  border-radius: 14px;
  z-index: -1;
  left: -22px;
  top: -22px;
  background-color: rgba(0, 0, 0, 1);
  height: 353px;
  max-width: 100%;
  position: absolute;
  width: 445px;
}

.image_section_left {
  border-radius: 0 14px 14px 0px;
  min-height: 430px;
  background-size: cover;
  background-position: right center;
}

.image_section_right {
  border-radius: 14px 0 0px 14px;
  min-height: 430px;
  background-size: cover;
  background-position: right center;
}

.images_left {
  position: relative;
  /* margin-right: 5vw; */
}

.images_right {
  position: relative;
  /* margin-left: 5vw; */
}

.heading_launcher {
  padding-left: 5vmin;
  margin-bottom: 50px;
}

.divider_launcher {
  background: #000;
  height: 2px;
  opacity: 0.25;
  width: 150px;
}



.heading_launcher_text {
  font-family: Poppins, sans-serif;
  font-size: 50px;
  font-weight: 400;
}

.close-button-home {
  position: relative;
  display: flex;
  align-self: flex-end;
  margin-right: 15px;
  color: #080707;
  font-size: 40px;
  font-weight: bold;
  transition: 0.2s;
}


.home_title {
  margin-bottom: 20px;
  font-size: 30px;
  font-family: Poppins, sans-serif;
  margin-top: 5vmin;
}

.home-adcontainer {
  margin: 0px auto;
  max-width: 1600px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.btnmore {
  background-color: #fff;
  border: 3px solid #000;
  border-radius: 10px;
  color: #000;
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  padding: 15px 42px;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.div_description {
  font-family: Poppins, sans-serif;
  font-size: 25px;
  font-weight: 400;
}



.search-text {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.search-text1 {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.div-search {
  width: 100%;
  padding-top: 20px;
  position: relative;

}

.bg_image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.shadow-div {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 30px 0px;
  border-radius: 0px 10px 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.newbusiness_headingtext {
  font-size: 1.8em;
  font-weight: 400;
  color: #000;
  font-family: Poppins, sans-serif;
  line-height: 1.37;
}

.imageBlog {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9px;
  min-height: 249px !important;
  position: relative;
  width: 100%;
  background-color: gray;
}

.blogimagecard {
  /* background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover; */
  border-radius: 9px;
  min-height: 349px !important;
  width: 100%;

  margin-bottom: 20px;
}

.cattext {
  font-size: 14px;
  font-weight: 400;
  color: #4a4747df;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}


.home_search_input:disabled {
  background-color: rgba(255, 255, 255, 255);
  border-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
}

.home_search_input {
  background-color: rgb(255, 255, 255);
  border-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
}

.BackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loader-home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.blogimage {
  height: 349px !important;
}

@media (max-width: 767px) {
  .home-banner {
    /* background-image: url("/public/natureimage_small.webp"); */
    /* height: 750px; */
    height: 60em;
    /* height: 100vmin; */
    display: flex;
    align-items: center;
    background-color: lightslategrey;
  }

  .home-dnd_area-module {
    padding-top: 190px;
    padding-right: 0px;
    padding-bottom: 100px;
    padding-left: 0px;
  }

  .blogimagecard {
    min-height: 200px !important;
  }

  .blogimage {
    height: 200px !important;
  }
}



.home-dnd_area-module {
  padding-top: 263px;
  padding-right: 0px;
  padding-bottom: 224px;
  padding-left: 0px;
  position: relative;
}


@media (min-width: 767px) {
  .home-banner {
    /* background-image: url("/public/natureimage_small.webp"); */
    height: 55em;
    /* height: 100vmin; */
    display: flex;
    background-color: lightslategrey;
    align-items: center;
  }
}


.gridStyle {
  /* width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  row-gap: 1%;
  column-gap: 1%; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1.5%;
  column-gap: 1%;

}

.itemStyle {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;

}

.itemContainer {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  /* This creates a 1:1 aspect ratio */
  position: relative;

}

.itemContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}