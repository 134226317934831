.blur_div {
    background-color: rgb(70 68 68 / 30%);
    padding-right: 10vmin !important;
    padding-left: 10vmin !important;
    padding-top: 5vmin;
    padding-bottom: 2vmin;
    backdrop-filter: blur(15px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    flex-direction: column;
    position: relative;
    z-index: 900;
}

.filter_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search_input {
    background-color: rgba(0, 0, 0, 0) !important;
    border-color: white !important;
    border-radius: 5px !important;
    min-height: 40px;
    color: white !important;
    padding: 0px 10px 0px 10px;
    width: 100%;
    border-width: thin !important;
    border-style: solid;
}

.search_input:focus {
    outline: none;
}

.search_input::placeholder {
    color: white !important;
}

.action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.filter-dropdown {
    position: absolute;
    right: 20px;
}

@media (max-width: 1024px) {
    .action-container {
        flex-direction: column;
        align-items: center;
    }

    .filter-dropdown {
        position: static;
        /* Reset positioning for smaller screens */
        order: 2;
        /* Ensure FilterDropDown appears first */
        margin-bottom: 10px;
    }

    .btnseach {
        position: static;
        /* Reset positioning for smaller screens */
        order: 1;
        /* Ensure btnseach appears second */
        margin-bottom: 10px;
    }
}


.btnseach {
    background-color: black;
    color: white;
    border-radius: 5px;
    height: 45px;
    display: inline-block;
    font-size: 0.9rem;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    cursor: pointer;
    /* margin-top: 8vmin; */
    border-style: solid !important;
    border-color: black !important;
    font-family: 'Poppins', sans-serif;
}

.banner_search {
    display: flex;
    align-items: center;
    padding-left: 20vw;
    padding-right: 20vw;
    padding-top: 12vmin;
    padding-bottom: 2vh;
    min-height: 300px;
}

.small_header_search {
    font-size: 17px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-top: 10px;
}



.enabled {
    background-color: white;
}

.main_search_input {
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    width: 100%;
    margin-top: 0px !important;
}

.main_search_input::placeholder {
    color: white;
}

.main_search_input:focus {
    border: none;
    outline: none;
}


.adoverlay {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px;
    right: 0;
    z-index: 1000
}


.adimage {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 9px;
    min-height: 250px !important;
    position: relative;
    width: 100%;
    background-color: gray;
}

.addcardcontainer {
    margin: 30px auto;
    max-width: 1600px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

}

.adtitle {
    font-family: 'Poppins', sans-serif;

}



.pagination_ad {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 20px;

    /* margin-top: 40vmin; */

}

.pagination_ad button {
    margin: 0 2px;
    padding: 4px 10px;
    border: none;
    cursor: pointer;
    color: black;
    align-self: center;
}

.pagination_ad button.active {
    /* background-color: #007bff; */
    border: 1px solid rgb(221, 216, 216);
    border-radius: 5px;
    color: black;
    align-self: center;
}

.addsection_cards {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    /* margin-bottom: 55px; */
    justify-content: center;
}

.searchdiv {
    width: 80%;
    padding-left: 5vmin
}

@media (max-width: 767px) {
    .addsection_cards {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
    }

    .banner_search {
        padding-left: 5vw !important;
        padding-right: 5vw !important;
    }

    .searchdiv {
        width: 100%;
        padding-left: 5px
    }

    .blur_div {
        padding-right: 3vmin !important;
        padding-left: 3vmin !important;
        padding-top: 12vmin;
        padding-bottom: 5vmin;
    }


}


@media (min-width: 768px) and (max-width: 1080px) {
    .addsection_cards {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .blur_div {
        padding-right: 10vmin !important;
        padding-left: 10vmin !important;
    }

    .banner_search {
        padding-left: 10vw;
        padding-right: 10vw;
    }
}

.load-more {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 15px !important;
    border-radius: 5px;
    background-color: rgb(235, 232, 232) !important;
}

.settings-icon {
    font-size: 20px;
    /* Adjust the size */
    color: #3498db;
    /* Blue */
    width: 30px;
    height: 30px;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .filter_div {
        display: flex;
        flex-direction: column;
    }
}

.filterdiv {
    display: flex;
    flex-direction: row;
}