.navbar .selected {
    border-bottom: 2px solid #ffffff;
    /* Example border-bottom styling */
}

.textcolorgray {
    color: #c0bebe !important;
}

.btnclose {
    background-color: white !important;
    display: flex;
    align-self: flex-end;
    border: none !important;
    margin-bottom: 20;
}

.modal-body-popup {
    position: relative;
    flex: 1 1 auto;
    padding: 0px 10px 10px 10px !important;
}

.text {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.forgottext {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.headertext_header {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.forgot_emailinput {
    outline: none;
    min-height: 45px;
    border-style: solid;
    border-color: white;
    width: 100%;
    padding: 0px 10px 0px 10px;
}

.notification-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 10px;
    min-width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    line-height: 1;
}

.notification-container {
    position: relative;
    display: inline-block;
}

.header-next-btn {
    border: 1px solid orange;
    background-color: white;
    color: orange;
    padding: 10px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
}