.label {
  font-family: 'Poppins', sans-serif;
  font-size: 2.2vmin;
  font-weight: 400;
  margin-bottom: 4px;
  color: 'black';
}



.inputmobile {
  min-height: 20px !important;
  margin-top: -20px
}

.btn_register {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 2.5vmin;

}

.header {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 3vmin;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
  text-decoration: underline;
  color: black;
}

.mainheader {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 4vmin;
  margin-bottom: 20px;
  text-align: center;
  color: black;
}

.circle-container {
  display: flex;
  margin-top: 30px;
  align-items: center;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #eee9e9;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.circle.active {
  background-color: #ffa600;
  border-radius: 5px;
  width: 20px;
  height: 10px;
  /* Use your desired color for the active circle */
}

.page {
  z-index: 2000;
}

.headertext1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: black;
}

.headertext_businessform {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: black;
}



.mt5 {
  margin-top: 5vmin;
}

.mt3 {
  margin-top: 3vmin;
}

.mb5 {
  margin-bottom: 5vmin;
}

.mb3 {
  margin-bottom: 3vmin;
}

.mb2 {
  margin-bottom: 2vmin;
}

.pd20 {
  padding: 20vmin;
}

.pd15 {
  padding: 15vmin;
}

@media (max-width: 767px) {
  .pd15 {
    padding: 25vmin 10px 10px 15px;
  }
}



.mt4 {
  margin-top: 4vmin;
}

.options-container {
  display: flex;
}


.file-upload-container {
  background-color: rgba(243, 243, 243, 0.714);
  border-radius: 5px;
  min-height: 250px;
  margin-right: 20px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}






.uploaded-image {
  width: 10vh;
  height: 10vh;
  border-radius: 5px;
  object-fit: contain;
}




.or-text {
  /* margin-top: 10px; */
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.search-btn {
  background-color: orange;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}


.preview-btn {
  background-color: rgb(167, 162, 155);
  border: none;
  color: white;
  height: 45px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
}


.darktext {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
}


.descriptiondiv {
  border: 1px solid rgb(221, 216, 216);
  min-height: 100px;
  border-radius: 5px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}




.descriptionlabel {
  border: none;
  /* Remove all borders */
  border-radius: 5px;
  padding: 10px;
  min-height: 150px;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: -25px;
  border-top: 1px solid rgb(223, 215, 215);
}

.heroimagediv {
  border: 1px solid rgb(221, 216, 216);
  min-height: 50px;
  border-radius: 5px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  margin-top: 20px;
  flex: 1;
  margin-right: 10px;
}

.gallery {
  border: 1px solid rgb(221, 216, 216);
  margin-top: 2vh !important;
  margin-right: 10px !important;
  padding: 15px !important;
  border-radius: 10px;
}


.right_column {
  height: calc(100%);
}

.quill {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: 25vmin !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}


.row-form {
  margin-left: 0px;
  margin-right: 0px;
}

.col-input-businessform {
  padding-right: calc(var(--bs-gutter-x)* 0.1) !important;
  padding-left: calc(var(--bs-gutter-x)* 0.1) !important;
}

.arrayimages_dashed-view {
  border: 1px dashed rgb(211, 206, 206);
  border-radius: 5px;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: rgba(243, 243, 243, 0.714);
  margin-bottom: 10px;
}