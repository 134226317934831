.vehiclename {
    /* margin-bottom: 40px; */
    position: relative;
    font-size: 3.5vmin;
    color: #000;
    font-family: Poppins, sans-serif;

}

.hero_image {
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 90vh;
    overflow-clip-margin: content-box;
    overflow: clip;

}


.brand {
    position: relative;
    font-size: 3vmin;
    color: #555353;
    font-family: Poppins, sans-serif;
    margin-top: 0px;
}

.hilightstext {
    font-weight: 500;
    margin-bottom: 40px;
    position: relative;
    font-size: 25px;
    color: #ff8f05e2;
    font-family: Poppins, sans-serif;
    line-height: 1.37;
    border-bottom: 1px solid rgb(246, 151, 9) !important;
}


.custom-list {
    list-style: none;
    /* Remove default list styles */
    padding: 0;
    /* Remove default padding */
    margin: 0;
    font-size: 2.5vmin;
}

.contact-info {
    margin-top: 30px;
}



.header5 {
    color: #000;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 35px;
}

.addvehicle_description {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 200;
    word-break: break-word;
    color: #000;
    /* margin-top: 20px; */
}

.add_hilights_conatiner {
    background-color: rgba(255, 177, 0, .1);
    border-radius: 9px;
    padding: 10px 38px;
}

.add_li {
    font-weight: 500;
    line-height: 0.1;
    list-style-type: none;
    margin-bottom: 5px;
    padding-left: 0px;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 2vmin !important;
}

.add_li::before {
    content: '';
    /* Empty content */
    display: inline-block;
    background-size: cover;
    /* Adjust as needed */
}

.enquire_btn {
    background-color: black;
    border-radius: 5px;
    color: white !important;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none !important;
    align-items: center;
    display: flex;
}

.msg_btn {
    background-color: black;
    border-radius: 5px;
    color: white;
    padding: 10px 26px;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.like_btn {
    background-color: white;
    border: 1px solid rgb(167, 162, 155);
    border-radius: 5px;
    color: black;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 100%;
    margin-top: 0px;
}

.add_contact_div {
    background-color: white;
    border-radius: 10px;
    border: 1.5px solid black;
    padding: 10px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact_header {
    font-weight: 400;
    font-size: 20px;
    color: black;
    font-family: Poppins, sans-serif;
    border-bottom: 1px solid rgb(48, 48, 47) !important;
}

.comapanyname {
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
}

.businessName {
    font-weight: 400;
    font-size: 16px;
    padding: 10;
}


.address_text {
    font-weight: 300;
    font-size: 16px;
    margin-top: 10px;
}

.share_btn {
    background-color: white;
    border: 1px solid rgb(167, 162, 155);
    border-radius: 5px;
    color: black;
    padding: 5px 5px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.add_galleryvw {
    padding-top: 0px !important;
    padding-bottom: 9px !important;
}

.add_row_fluid {
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;
    padding-left: 8vw;
    padding-right: 8vw;
}

.gallery_row {
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;
}

.add_rowimage {
    --bs-gutter-x: 0.9rem !important;
}

.add_pic_items {
    /* margin-bottom: 8px; */
    margin-bottom: 15px;
    display: grid;
}

.add_pic {
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    justify-content: center;
    /* overflow: hidden; */
    position: relative;
    text-align: center;
    padding-bottom: 40%;
}

.add_pic1 {
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding-bottom: 65%;
}

.vehicle_details_div {
    background-color: rgba(247, 240, 240, 0.5);
    display: flex;
    flex-direction: column;
    padding: 3vmin 5vmin 0vmin 5vmin;
    margin-bottom: 20px;
}

.techical_data_div {
    padding: 0vmin 8vmin;
    margin-bottom: 20px;
}


.small_header {
    font-weight: 500;
    margin-bottom: 1s0px;
    position: relative;
    font-size: 20px;
    color: #000;
    font-family: Poppins, sans-serif;
}

.basic_detail_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(167, 162, 155);
    margin-top: 5px;
}

.preview_normaltext {
    font-weight: 400;
    position: relative;
    font-size: 15px;
    color: #343333;
    font-family: Poppins, sans-serif;
}

.normaltextvalue {
    font-weight: 400;
    position: relative;
    font-size: 15px;
    color: #100c0c;
    font-family: Poppins, sans-serif;
}

.image-grid {
    display: flex;
    /* gap: 0.8rem; */
    /* padding: 1rem; */
    /* Padding for the container */
    border-radius: 5px;
    flex-wrap: wrap;

}

.image-row {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    flex: 0 0 7rem;
    height: 25vw;
    border-radius: 10px;
    margin-left: 8px;
    margin-right: 8px;
    /* Ensure each image row takes 200px width */
}

.image-row img {
    width: 120px;
    height: auto;
    display: block;
    transition: all 0.3s ease;
    border-radius: 10px;
}

.image-row .image-title {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 120px;
    /* Extend to the right to cover full width */
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    display: flex;
    align-items: center;
    /* Center items vertically */
    justify-content: flex-start;
    /* Align items to the left */
    padding: 0.8rem;
    transition: opacity 0.3s ease;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    border-radius: 10px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    /* Rotate text to appear bottom-to-top */
}


.image-row.expanded {
    flex: 0 0 30%;
    border-radius: 10px;
    padding: 2px;
    background-color: gainsboro;
    /* Take full width of the container when expanded */
    /* z-index: 1; */
}

.image-row.expanded img {
    width: 30vw;
    height: 200px;
    object-fit: contain;
}

.image-row.expanded .image-title {
    /* display: none; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* Extend to the right to cover full width */
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    display: flex;
    align-items: flex-start;
    /* Center items vertically */
    justify-content: flex-start;
    /* Align items to the left */



    border-radius: 10px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
}



.admessage_quil {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
    height: 40vmin;
    border-radius: 5px !important;
    outline: none;
    border-color: #cccc;
}


.admessage_quil .ql-container.ql-snow {
    border: 1px solid #ccc;
    border-radius: 5px;
}

.image-details {
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    color: #333;
    /* padding: 1rem; */
    width: 100%;
}

.image-details h2 {
    margin: 0 0 0.5rem;
}

.image-details p {
    margin: 0;
}

.selectedHeader {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: rgb(17, 17, 17);
}

.selectedDescription {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: rgb(17, 17, 17);
}

.expandable-text p {
    margin: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: rgb(17, 17, 17);
}

.navbar_sections {
    position: relative;
    width: 100%;
    background-color: white;
    padding-left: 10vmin;
    padding-right: 10vmin;
    margin-top: 20px;
}

.navbar_sections ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.navbar_sections li {
    display: inline;
    /* margin-bottom: 10px; */
    padding-bottom: 10px !important;
}



.active-section {
    border-bottom: 3px solid #100f0f !important;
    text-align: center;
}

.inactive {
    border-bottom: 1px solid #100f0f !important;
    text-align: center;

}

.add_content {
    background-color: white;
    /* padding-top: 40px; */
}



.collapsed {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: rgb(17, 17, 17);
    max-height: 3em;
    /* Approximate height for 2 lines of text */
}

button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    padding: 0;
    margin-top: 0.5rem;
    /* text-decoration: underline; */
}


.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}



.close {
    position: absolute;
    top: 10px;
    right: 25px;
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 30px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.prev {
    left: 0;
    border-radius: 3px 0 0 3px;
}

.next {
    right: 0;
    border-radius: 3px 3px 0 0;
}

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.img {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

@media (max-width: 580px) {
    .image-row.expanded img {
        width: 65vw;
        height: 200px;
        object-fit: contain;
    }

    .image-row .image-title {
        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 1rem;
        /* Rotate text to appear bottom-to-top */
    }

    .image-row.expanded .image-title {
        font-size: 1rem;
    }

}

.soldtext {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 1rem;
}