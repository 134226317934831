.top-label-input {
  position: relative;
  margin-bottom: 15px;
  margin-top: 0px;
}






.inputtel {
  margin-top: 0px !important;
  min-height: 20px;
  margin-left: 5px;
  border-left: 1px solid rgb(221, 216, 216);
  border-right: none;
  border-bottom: none;
  border-top: none;
  border-radius: 0px;

}

.mobilecode {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}

.mobiletext {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #898585;
  display: flex;
  align-items: center;
  margin-left: 5px;
}



.inputtel:focus {

  border-left: 1px solid rgb(221, 216, 216);
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
}

.PhoneInputCountryIconUnicode {
  display: none !important;
}


.toplabaleinput:focus {
  border: 1px solid #ccc !important;
  outline: none;
  min-height: 45px;
}

.toplabaleinput {
  min-height: 45px;
  border-color: lightgray;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  border-style: solid !important;
  border-width: thin;
  width: 100%;
}