.div_dropdown_wotlbl {
  border: 1px solid rgb(221, 216, 216);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0) !important;
}

.dropdown_overview_wotlbl {
  position: relative;
  margin-top: 0px;

}

.headertext_drop {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: black;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.input_dropdown_wotlbl {
  border: none !important;
  outline: none;
  margin-top: 0px !important;
  color: white !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.dropdown-menu-details_wotlbl {
  /* position: absolute;
  top: calc(100% + 5px);
  right: 0;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 5000; */
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-height: 100px;
  /* Set a fixed height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  z-index: 5000;

}

.dropdown-icon_wotlbl {
  color: white !important;
  /* Change color as needed */
  padding-left: 15px;
  padding-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;

}

.dropdown-item_wotlbl {

  /* border-bottom: 1px solid #292828 !important; */
  cursor: pointer;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}