.filter_dropdown_overview {
    position: relative;
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 5px;
    width: 16rem;
}

.filter_div_dropdown {
    border-radius: 5px;
}

.filter_input_dropdown {
    border: none !important;
    outline: none;
    margin-top: 0px !important;
    min-height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
}

.filter_headertext_dropdown {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: black;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.filter_dropdown-icon {
    color: white;
    /* Change color as needed */
    border-left: 1px solid rgb(221, 216, 216);
    padding-left: 15px;
    padding-right: 10px;
    height: 100%;
    display: flex;
    align-items: center;

}

.filter_dropdown-menu-details {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-height: 200px;
    /* Set a fixed height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    z-index: 5000;
    padding-bottom: 5px;
}

.filter_dropdown-item {
    padding: 10px;
    /* border-bottom: 1px solid #292828 !important; */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.filter_dropdown-item:hover {
    background-color: #f5f5f5;

}