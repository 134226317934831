.order-summary {
    font-family: 'Poppins', sans-serif;
    background-color: #f3f4f6;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding: 20px;
}

.order-summary-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 80px;
}

.order-card {
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
    max-width: 1040px;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.order-content {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .order-content {
        flex-direction: row;
        align-items: center;
    }
}

.order-image {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.order-image img {
    width: 250px;
    height: 160px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.order-details {
    flex: 1;
    text-align: left;
    padding-left: 20px;
}

.order-details h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
}

.order-details p {
    font-size: 14px;
    margin: 2px 0;
}

.pay-button {
    background-color: #facc15;
    color: black;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 100%;
    margin-top: 15px;
    border: none;
}

.pay-button:hover {
    background-color: #eab308;
}

.cancel-button-order {
    background-color: gray;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    /* padding: 8px; */
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cancel-button:hover {
    background-color: black;
    color: white;
}