.app {
    display: flex;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    height: 100%;
}

/* .left-panel {
    width: 25%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ccc;
} */

.search-box {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.contact_name {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: black;
    cursor: default;
    /* Default cursor for non-links */
}

.contact_name.link {
    color: blue;
    /* Optional: Change link color */
    cursor: pointer;
    /* Pointer cursor to indicate it's clickable */
    text-decoration: none;
    /* Default state, no underline */
}

.contact_name.link:hover {
    text-decoration: underline;
    /* Underline on hover to indicate a link */
}

.contact_ad {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: rgb(148, 145, 145);
}



.search-box-div {
    background: #fbe19ae7 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;

    padding-left: 10px;
    padding-right: 10px;
}

.search-box input {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: none !important;
    background: #fbe19ae7 !important;
    border-radius: 5px;
}

.date-group {
    margin-bottom: 20px;
}

.date-label {
    text-align: center;
    color: #888;
    margin-bottom: 15px;
    margin-top: 10px;
}

.message {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: 90%;
}



.send-message-box {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    background: #f5f5f5;
}

.send-message-box input {
    flex: 1;
    padding: 5px;
    font-size: 16px;
}

.send-message-box button {
    padding: 5px 10px;
    font-size: 16px;
    margin-left: 10px;
}


.search-box {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.search-box input {
    width: 100%;
    padding: 5px;
    font-size: 16px;
}


.user_image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
}

.left_text {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}

.leftpannel_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5vmin;
    padding-left: 5vmin;
}

.conversation-header {
    padding: 10px;
    border-bottom: 1px solid #f1e9e9;
    background-color: #f5f5f5c3;
    justify-content: center;
    display: flex;
    align-items: center;

}

.load-more-button {
    border: none;
    background-color: rgba(0, 0, 0, 0);
}

.messages {
    flex: 1;
    padding: 10px 20px 10px 20px;
    overflow-y: auto;
}

.date-group {
    margin-bottom: 20px;
}

.date-label {
    text-align: center;
    color: #888;
    margin-bottom: 10px;
}

.message-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

}

.btn-send {
    border-right: 1px solid #ccc;
    padding-right: 5px;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
}

.btn-file {
    border: 0px;
    background-color: rgba(0, 0, 0, 0) !important;
}

.linediv {
    height: 100%;
    width: 1px;
    background-color: #ccc
}

.message-input {
    flex: 1;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13px !important;
    border: none !important;
    resize: vertical;
    /* Allows vertical resizing */
    max-height: 150px;
    color: gray;
    background: #fbe19ae7 !important;
    outline: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.message_quil {
    margin-top: 0px;
    margin-bottom: 0px;
    border: none !important;
}

.message_quil .ql-container.ql-snow {
    /* border: 0px solid #ccc; */
    border-color: #fbe19ae7 !important;
}


.message-time-sent {
    font-size: 0.8em;
    color: #666;
    align-self: flex-end;
}

.message-time-received {
    font-size: 0.8em;
    color: #666;
    align-self: flex-start;
}


.message {
    padding: 10px;
    border-radius: 5px;
    max-width: 100%;
    display: inline-block;
    margin-bottom: 5px;
}

.message.sent {
    background: #fdae25;
    align-self: flex-end;
    float: right;
    clear: both;
    color: white;
    display: flex;
    flex-direction: row;
}

.message.received {
    background: #faecaded;
    align-self: flex-start;
    float: left;
    clear: both;
    color: gray;
}

.send-message-box {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    background: white
}

.send-message-box-innerdiv {
    background: #fbe19ae7 !important;
    display: flex;
    width: 100%;
    border-radius: 5px;

}

.send-message-box input {
    flex: 1;
    padding: 5px;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0);

}

.send-message-box button {
    padding: 5px 10px;
    font-size: 16px;
    margin-left: 10px;
}

.search-input-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13px !important;
    outline: none;
    min-height: 40px;
}

.popover-content-message {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 5px;
    z-index: 100;
}

.popover-content-message button {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px 0;
    background: none;
    border: none;
    cursor: pointer;
    color: #000;
}

.popover-content-message button:hover {
    background-color: #f0f0f0;
}

.message-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.left-panel {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    /* Add overflow for scrolling */
    /* width: 100%; */
    /* max-width: 400px; */
    /* Adjust max-width as needed */
}

.right-panel {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* Add overflow for scrolling */
    /* width: 100%; */
}

/* Enable horizontal scrolling for Row */
.message-row {
    flex-wrap: nowrap;
    overflow-x: auto;
}

/* Optional: make it responsive */
/* @media (max-width: 768px) {
    .left-panel,
    .right-panel {
        min-width: 100vw;
    }

    .left-panel {
        max-width: 100vw;
    }

    .right-panel {
        max-width: 100vw;
    }
} */
.hidden {
    display: none;
}


@media (min-width: 768px) {
    .container-message {
        flex-direction: row;
    }

    /* .left-panel {
        width: 100%;
    }

    .right-panel {
        width: 100%;
    } */
}

.custom-content p {
    margin-bottom: 0;
}